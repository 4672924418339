// src/console.js

import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

function ConsolePage() {
  return (
    <Container className="mt-4">
      <Card className="shadow-lg">
        <Card.Body>
          <h1>Console Page</h1>
          <p>Welcome to the console page. Here you can manage and monitor your AI resources and activities.</p>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default ConsolePage;
