import React from 'react';
import Tap2Earn from './Tap2Earn';

const Tap2EarnLayout = () => {
    return (
        <div>
            <Tap2Earn />
        </div>
    );
};

export default Tap2EarnLayout;
