import React, { useState, useEffect } from 'react';
import './Tap2Earn.css';

const Tap2Earn = () => {
    const [userId, setUserId] = useState(null);
    const [coins, setCoins] = useState(0);
    const [energy, setEnergy] = useState(1500);
    const [message, setMessage] = useState('');

    // Automatically get Telegram user ID or phone number on load
    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            const initData = window.Telegram.WebApp.initDataUnsafe;
            if (initData && initData.user) {
                setUserId(initData.user.id || initData.user.username);
                window.Telegram.WebApp.ready(); // Ensure the WebApp is ready
                window.Telegram.WebApp.expand(); // Expand to full-screen if possible
            } else {
                setMessage('Telegram user data not found.');
            }
        } else {
            setMessage('Telegram WebApp not initialized.');
        }
    }, []);

    const showMessage = (msg, isError = true) => {
        setMessage({ text: msg, isError });
    };

    const updateGameInfo = (user) => {
        setCoins(user.coins);
        setEnergy(user.energy);
    };

    useEffect(() => {
        if (userId) {
            // Fetch user data from backend (create user if not exist)
            fetch('/api/users/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.token) {
                        updateGameInfo(data.user);
                        showMessage('Logged in successfully!', false);
                    } else {
                        showMessage('Login failed.');
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                    showMessage('Something went wrong.');
                });
        }
    }, [userId]);

    const handleEarn = () => {
        if (energy > 0) {
            setCoins(coins + 1);
            setEnergy(energy - 1);
            showMessage('Coin earned!', false);
        } else {
            showMessage('Not enough energy!');
        }
    };

    return (
        <div className="tap2earn-container">
            <h1>Tap2Earn Game</h1>

            {userId ? (
                <div id="game-section">
                    <div id="user-info">
                        <p>Coins: <span id="coins">{coins}</span></p>
                        <p>Energy: <span id="energy">{energy}</span></p>
                    </div>
                    <button type="button" id="earn-btn" onClick={handleEarn}>
                        Tap to Earn Coins
                    </button>
                </div>
            ) : (
                <div id="message" style={{ color: 'red' }}>
                    Loading user data...
                </div>
            )}

            {message && (
                <div id="message" style={{ color: message.isError ? 'red' : 'green' }}>
                    {message.text}
                </div>
            )}
        </div>
    );
};

export default Tap2Earn;
